import { useEffect } from 'react';
import { useGetPage } from '../hooks/useGetPage';

const NotFoundPage = () => {
  useGetPage('');
  useEffect(() => {}, []);

  return <div className="display-flex">Page was not found</div>;
};

export default NotFoundPage;
