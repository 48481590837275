import axios, { AxiosError } from 'axios';
import { baseURL } from '../constants/generic';
import { auth } from '@bepro-travel/fe.shared';

export const configureAxios = () => {
  axios.defaults.baseURL = baseURL; /// local
  // axios.defaults.baseURL = 'https://v23b2c.beprotravel.com/'; ///dev
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.defaults.headers.common.Accept = 'application/json';

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error: AxiosError) => {
      if (error.response && error.response.status === 401) {
        window.location.href = '/login';
        localStorage.removeItem('user');
        auth.getUserToken();
      }
    }
  );
};
