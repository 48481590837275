import { useContext, useEffect } from 'react';
import { MainContext } from '../contexts/MainContext';
import { getPage } from '../services/cms';

export const useGetPage = (url: string) => {
  const { setFooter, setHeader, setSiteConfig } = useContext(MainContext);

  useEffect(() => {
    (async () => {
      const page = await getPage(url);

      if (page) {
        setHeader(page.pageHeader);
        setFooter(page.pageFooter);
        setSiteConfig(page.siteConfig);
      }
    })();
  }, []); // eslint-disable-line
};
